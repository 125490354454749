import React from 'react'
import loadable from '@loadable/component';
import LayoutTwo from '../components/layoutTwo';
import SEO from "../components/seo"
import { PageLinks } from "../common/site/page-static-links";
import { graphql } from 'gatsby';

const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));
const StaticIntro = loadable(() => import("../components/StaticIntro/StaticIntro"));
const NewsDetailDesc = loadable(() => import("../components/NewsDetailDesc/NewsDetailDesc"));
const NewsInsightsSlider = loadable(() => import("../components/NewsInsightsSlider/NewsInsightsSlider"));

const NewsDetails = ({ data }, props) => {
    const PageData = data?.strapiBlog

    const MoreNews = data?.allStrapiBlog.edges

    let breadcrumData;

    if (PageLinks?.news_parent_label) {
        breadcrumData = { parentlabel: PageLinks.news_parent_label, parentlink: PageLinks.news_parent, subparentlabel: PageLinks.news_label, subparentlink: PageLinks.news, title: PageData.title }
    } else {
        breadcrumData = { parentlabel: PageLinks.news_label, parentlink: PageLinks.news, title: PageData.title }
    }

    return (
        <LayoutTwo popularSearch="Generic pages">
            <div className="layout-padding-top">
                <BreadcrumbModule {...breadcrumData} />

                <StaticIntro
                    title={PageData.title}
                    staticClass="static-intro-secondary"
                    tag="news-detail"
                    {...PageData}
                />

                <NewsDetailDesc {...PageData} />

                <NewsInsightsSlider newsDetail={MoreNews} tag="news-details" />

            </div>
        </LayoutTwo>
    )
}

export const Head = ({ data }) => {
    const PageData = data?.strapiBlog
    var ldJson = {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": typeof window !== 'undefined' ? window.location.href : ''
        },
        "headline": PageData?.title,
        "image": PageData?.banner_image ? PageData?.banner_image?.url : '',
        "datePublished": PageData?.publishedAt,
        "dateModified": PageData?.updatedAt,
        "author": {
            "@type": "Person",
            "name": PageData.author ? PageData.author?.title : process.env.GATSBY_SITE_NAME
        },

        "publisher": {
            "@type": "Organization",
            "name": process.env.GATSBY_SITE_NAME,
            "logo": {
                "@type": "ImageObject",
                "url": process.env.GATSBY_SITE_URL + `/images/logo.png`
            }
        },
        "description": `Read about ${PageData.title} here and subscribe to our newsletter to stay up-to-date about everything going on at ${process.env.GATSBY_SITE_NAME}.`
    }

    return (
        <SEO title={PageData.title} description={`Read about ${PageData.title} here and subscribe to our newsletter to stay up-to-date about everything going on at ${process.env.GATSBY_SITE_NAME}.`}>
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson) }}
            />
        </SEO>
    )
}

export default NewsDetails

export const query = graphql`
query ($page_id: Int) {
    strapiBlog(strapi_id: {eq: $page_id}) {
        ...BlogFragment
        banner_image {
          alternativeText
          url
        }
        author {
          slug
          name
        }
        publishedAt(formatString: "YYYY-MM-DD")
        updatedAt(formatString: "YYYY-MM-DD")
        add_blog_content {
            ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
                ...PlainContentFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE {
                ...ImageFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_ACCORDION {
                ...AccordionFragment
            }
        }
    }
    
    allStrapiBlog(filter: {publish: {eq: true}, strapi_id: {ne: $page_id}}) {
        edges {
            node {
                ...BlogFragment
                tile_image {
                    alternativeText
                    url
                }
            }
        }
    }
  }
`